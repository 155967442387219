/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {useEffect, useState} from 'react'

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  return (
    <>
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.dashboard?.read) && (
        <SidebarMenuItem to='/dashboards' icon='dashboard' title='Dashboard' />
      )}
      {(permissions?.property?.read ||
        (currentUser?.profileType === 'superadmin' && currentUser?.app?.crm)) && (
        <SidebarMenuItem to='/property' icon='apartment' title='Property' />
      )}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.clients?.read) && (
        <SidebarMenuItem to='/clients' icon='users' title='Clients' />
      )}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.pipeline?.read) && (
        <SidebarMenuItem to='/pipeline' icon='Library' title='Pipeline' />
      )}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.sales?.read) && <SidebarMenuItem to='/sales' icon='sortarrow' title='Sales' />}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.agents?.read) && <SidebarMenuItem to='/agents' icon='user' title='Agents' />}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.maps?.read) && <SidebarMenuItem to='/maps' icon='maps' title='Maps' />}
      {((currentUser?.profileType === 'superadmin' && currentUser?.app?.crm) ||
        permissions?.performance?.admin) && (
        <SidebarMenuItem to='/agent-report' icon='sortarrow' title='Performance' />
      )}
      {currentUser?.profileType !== 'marketing' &&
        (currentUser?.app?.marketing === true ||
          // currentUser?.app?.telecom === true ||
          (currentUser?.app?.hrm === true && currentUser?.app?.telecom !== true)) && (
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
            </div>
          </div>
        )}
      {currentUser?.app?.marketing === true && (
        <SidebarMenuItemWithSub
          to='/marketing'
          title='Marketing'
          fontIcon='barchart'
          icon='element-12'
        >
          <SidebarMenuItem to='/marketing/marketing1' icon='element-7' title='Marketing1' />
          <SidebarMenuItem to='/marketing/marketing2' icon='switch' title='Marketing2' />
          <SidebarMenuItem to='/marketing/marketing3' icon='switch' title='Marketing3' />
        </SidebarMenuItemWithSub>
      )}
      {/* {currentUser?.app?.telecom === true && (
        <SidebarMenuItemWithSub to='/telecom' title='Telecom' fontIcon='Vector'> */}
      {/* <SidebarMenuItem to='/telecom/dashboard' icon='element-7' title='Dashboard' fontIcon='bi-layers' /> */}
      {/* <SidebarMenuItem to='/telecom/calls' icon='Vector' title='Calls' />
          <SidebarMenuItem to='/telecom/failedcalls' icon='Vector' title='Failed Calls' />
          {(currentUser?.profileType === 'superadmin' || currentUser?.profileType === 'admin') && (
            <SidebarMenuItem to='/telecom/live' icon='Vector' title='Live' />
          )}
          <SidebarMenuItem to='/telecom/phonebook' icon='Vector' title='Phonebook' />
          <SidebarMenuItem to='/telecom/dialer' icon='Vector' title='Dialer' />
        </SidebarMenuItemWithSub>
      )} */}
      {(permissions?.employee?.read ||
        permissions?.leavemanagement?.read ||
        permissions?.attendance?.read ||
        permissions?.payroll?.read ||
        permissions?.timetracker?.read ||
        permissions?.hrmanalytics?.read ||
        permissions?.hrmdocuments?.read ||
        (currentUser?.profileType === 'superadmin' && currentUser?.app?.hrm)) && (
        <SidebarMenuItemWithSub to='/hrm' title='HRM' icon='user'>
          <SidebarMenuItem to='/error/404' icon='user' title='Dashboard' />
          {(permissions?.payroll?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/payroll' icon='user' title='Payroll' />
          )}
          {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/empDepartment' icon='user' title='Department' />
          )}
          {(permissions?.employee?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/employee' icon='user' title='Employees' />
          )}
          {(permissions?.attendance?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/attendance' icon='user' title='Attendance' />
          )}
          {(permissions?.timetracker?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/time-tracker' icon='user' title='Time Tracker' />
          )}
          {(permissions?.leavemanagement?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/leaveManagement' icon='user' title='Leave Management' />
          )}
          {(permissions?.performance?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/agent-report' icon='user' title='Performance' />
          )}
          {currentUser?.profileType === 'superadmin' && (
            <SidebarMenuItem to='/reports' icon='user' title='Reports' />
          )}
          {(permissions?.hrmanalytics?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/submissionReports' icon='user' title='Submitted Reports' />
          )}
          {(permissions?.hrmdocuments?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/hrm/documents' icon='user' title='Documents' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {(permissions?.accountsexpenses?.read ||
        permissions?.accountant?.read ||
        permissions?.journal?.read ||
        permissions?.reports?.read ||
        permissions?.accountsdocuments?.read ||
        (currentUser?.profileType === 'superadmin' && currentUser?.app?.accounts)) && (
        <SidebarMenuItemWithSub to='/account' title='Accounts' fontIcon='writing'>
          <SidebarMenuItem to='/StoreAnalytics' title='Dashboard' fontIcon='bi-layers' />
          {(permissions?.accountsexpenses?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/account/expenses' title='Expenses' fontIcon='bi-layers' />
          )}
          {(permissions?.accountant?.read ||
            permissions?.journal?.read ||
            currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItemWithSub to='/account' title='Accountant' fontIcon='writing'>
              {(permissions?.journal?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/account/journals'
                  title='Journal Entry'
                  fontIcon='bi-layers'
                />
              )}
              {(permissions?.accountant?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/account/chartofaccounts'
                  title='Chart of accounts'
                  fontIcon='bi-layers'
                />
              )}
              {(permissions?.accountant?.read || currentUser?.profileType === 'superadmin') && (
                <SidebarMenuItem
                  to='/account/categoryofaccounts'
                  title='Category of accounts'
                  fontIcon='bi-layers'
                />
              )}
            </SidebarMenuItemWithSub>
          )}
          {(permissions?.reports?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/account/reports' title='Reports' fontIcon='bi-layers' />
          )}
          {(permissions?.accountsdocuments?.read || currentUser?.profileType === 'superadmin') && (
            <SidebarMenuItem to='/account/documents' title='Documents' fontIcon='bi-layers' />
          )}
        </SidebarMenuItemWithSub>
      )}

      {(currentUser?.task?.events === true ||
        currentUser?.task?.calendar === true ||
        currentUser?.task?.storage === true ||
        currentUser?.task?.sales === true ||
        currentUser?.task?.accounts === true ||
        currentUser?.task?.project === true) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tools</span>
          </div>
        </div>
      )}
      {(currentUser?.task?.calendar === true ||
        currentUser?.profileType === 'superadmin' ||
        permissions?.calendar?.read) && (
        <SidebarMenuItem to='/calendar' icon='calendar' title='Calendar' />
      )}
      {(currentUser?.task?.events === true ||
        currentUser?.profileType === 'superadmin' ||
        permissions?.events?.read) && (
        <SidebarMenuItem to='/events' icon='calendar' title='Events' />
      )}
      {(currentUser?.profileType === 'superadmin' || permissions?.tasks?.read) && (
        <SidebarMenuItem to='/tasks' icon='calendar' title='Tasks' />
      )}
      {(currentUser?.profileType === 'superadmin' || permissions?.meetings?.read) && (
        <SidebarMenuItem to='/meetings' icon='calendar' title='Meetings' />
      )}
      {(currentUser?.profileType === 'superadmin' || permissions?.sprint?.read) && (
        <SidebarMenuItem to='/sprint' icon='users' title='Sprints' />
      )}
      {(currentUser?.task?.storage === true ||
        currentUser?.profileType === 'superadmin' ||
        permissions?.storage?.read) && (
        <SidebarMenuItem to='/storage' icon='case' title='Storage' />
      )}
      {(currentUser?.task?.expenses === true ||
        currentUser?.profileType === 'superadmin' ||
        permissions?.expenses?.read) && (
        <SidebarMenuItem to='/expenses' icon='writing' title='Expenses' />
      )}
      {/* only allow the profiletype with admin keyword within */}
      {currentUser?.profileType !== 'superadmin' &&
        currentUser?.profileType?.toLocaleLowerCase().includes('admin') && (
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
            </div>
          </div>
        )}
      {currentUser?.profileType !== 'superadmin' &&
        currentUser?.profileType?.toLocaleLowerCase().includes('admin') && (
          <SidebarMenuItem to='/user-management/users' title='User management' fontIcon='bi-gear' />
        )}
      {currentUser?.profileType === 'superadmin' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Super Admin</span>
          </div>
        </div>
      )}
      {currentUser?.profileType === 'superadmin' && (
        // <SidebarMenuItemWithSub to='/user-management'title='user-management'
        // // fontIcon='element'
        // icon='element-12'>
        // <SidebarMenuItem to='/user-management/sales' icon='element-7' title='Hrm1'  />
        // </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/superuser-management' title='User Management' fontIcon='bi-gear' />
      )}
      {currentUser?.profileType === 'superadmin' && (
        // (
        //   <SidebarMenuItemWithSub to='/role-management'title='role-management'
        //   // fontIcon='element'
        //   icon='element-12'>
        //     {
        //       departments?.map((department) => (
        //         <SidebarMenuItem to={`/role-management/${department?._id}`} icon='element-7' title={department?.name} fontIcon='bi-layers' key={department?._id} />
        //       ))
        //     }
        //   {/* <SidebarMenuItem to='/role-management/sales' icon='element-7' title='Hrm1' fontIcon='bi-layers' /> */}
        //   </SidebarMenuItemWithSub>

        // )
        <SidebarMenuItem
          to='/role-management'
          // icon='element-7'
          title='Role Management'
          fontIcon='bi-gear-fill'
        />
      )}
      {currentUser?.profileType === 'superadmin' && (
        // <SidebarMenuItemWithSub to='/department'title='department'
        // fontIcon='element'
        // icon='element-12'>
        <SidebarMenuItem
          to='/department/department-list'
          // icon='element-7'
          title='Department'
          fontIcon='bi-building'
        />
        // {/* </SidebarMenuItemWithSub> */}
      )}
      {currentUser?.profileType === 'superadmin' && (
        // <SidebarMenuItemWithSub to='/modules'title='modules'
        // fontIcon='element'
        // icon='element-12'>
        <SidebarMenuItem
          to='/modules/module-list'
          icon='dashboard'
          title='Modules'
          fontIcon='element'
        />
        // </SidebarMenuItemWithSub>
      )}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem to='/user-management/users' icon='abstract-28' title='User management' fontIcon='bi-layers'/>
 
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
