import React, {ChangeEvent, FC, FormEvent, useEffect, useRef, useState} from 'react'
import { deleteProperty, editProperty, getPropertysec, postImages, postMultipleImages } from '../core/_requests'
import { PropertyWidget } from './PropertyWidget'
import { AddProperty, Property } from '../core/_models'
import { useAuth } from '../../../modules/auth'
import { getPropertysecbyAgentId } from '../../agent/core/_requests'
import grouppic from './camera.png';
import { KTIcon } from '../../../../_metronic/helpers'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setPropertyList } from '../../../redux/property/secondarySlice'



const SecondaryList: FC = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const propertyData = useSelector((state: RootState) => state.property.secondary.propertyList)
  const secondaryFetch = useSelector((state: RootState) => state.property.secondary.fetch)
  const [propertyList, setpropertyList] = useState<Property[]>(propertyData)
  const [propertyId, setpropertyId] = useState<string>('')
  const {currentUser} =useAuth()
  const dispatch = useDispatch()
  const [fieldValue, setFieldValue] = useState<any>({
    images: [],
    imagesname: [],
  });
  const [existingMediaImages, setExistingMediaImages] = useState<any>([]);

  const [formDataProp, setFormDataProp] = useState<AddProperty>({
    name: '',
    productPic: '',
    startingprice: '',
    lastprice: '',
    address: '',
    lat:'',
    lng:'',
    projectType:'',
    area:'',
    status:'',
    handOver:'',
  });
  const [imagePreview, setImagePreview] = useState<any>(null);
	const [selectedImage, setSelectedImage] = useState<any>(null);
  const [images, setImages] = useState<File[]>([]);
  const [imagesname, setImagesname] = useState([]);
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleid = (id:string) => {
    setpropertyId(id)
  }

  // async function convertImageUrlToFile(imageUrl, fileName) {
  //   // Fetch the image as a blob
  //   const response = await fetch(imageUrl);
  //   const blob = await response.blob();
  
  //   // Create a File from the blob
  //   const file = new File([blob], fileName, { type: blob.type });
  
  //   return file;
  // }

  const handleImageChangeMedia = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...selectedImages]);
      // const timestamp = Date.now();
      // Assuming you want to store only the first file name
      const newImagesname = [...imagesname]
      for (let i = 0; i < selectedImages.length; i++) {
        // newImagesname.push(timestamp+selectedImages[i]?.name)
        newImagesname.push(selectedImages[i]?.name)
      }
      setImagesname(newImagesname)
      setFieldValue((prev)=>{
        return{
          ...prev,
          imagesname: newImagesname
        }
      })

      // Update Formik values
      setFieldValue((prev)=>{
        return{
          ...prev,
          images: [...images, ...selectedImages]
        }
      })
     
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    const newImagesName = [...fieldValue.imagesname];
    newImagesName.splice(index, 1);
    // setFieldValue('images', newImages);
    setFieldValue((prev)=>{
      return{
        ...prev,
        images: newImages,
        imagesname: newImagesName
      }
    })
  };

  const handleRemoveExistingMediaImage = (index: number) => {
    const newImages = [...existingMediaImages];
    newImages.splice(index, 1);
    setExistingMediaImages(newImages);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // This will set the image preview URL
      };

      setFormDataProp({
        ...formDataProp,
        productPic: Date.now() + file?.name,
      });

      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDataProp({
      ...formDataProp,
      [name]: value,
    });
  };

  const handleSubmit = async(e: FormEvent) => {
    e.preventDefault();
    const imagesFormData = new FormData();

    for (let i = 0; i < images.length; i++) {
      imagesFormData.append('image', fieldValue.images[i]);
    }
    imagesFormData.append('name', fieldValue.imagesname);

    const imageData = new FormData();
    const productPic = formDataProp.productPic;
    imageData.append('name', productPic);
    imageData.append('image', selectedImage);
    await postMultipleImages(imagesFormData).then(async () =>{
      await postImages(imageData).then(
        async (res) => {
          const data = {
            ...formDataProp,
            mediaPic: [...existingMediaImages, ...fieldValue.imagesname]
          }
          console.log(data)
          await editProperty(data, propertyId).then((response) => {
            const updateProperty = propertyList.map(item => item._id === response._id ? response : item)
            setpropertyList(updateProperty)
            dispatch(setPropertyList(updateProperty))
          setFormDataProp({
            name: '',
            productPic: '',
            startingprice: '',
            lastprice: '',
            address: '',
            lat:'',
            lng:'',
            projectType:'',
            area:'',
            status:'',
            handOver:'',
          })
          setImages([])
          setpropertyId("")
          setFieldValue({
            images: [],
            imagesname: [],
          })
          const closeModalButton = document.getElementById('closeModalButtonEdit');
          if (closeModalButton) {
            closeModalButton.click();
          }
          })
          
        }
      )
    })
    

    // Add your logic for handling form data here
  };

  const handledelete = async() => {
    const res = await deleteProperty(propertyId)
    console.log(res)
    if (res === "deleted"){
      const updatedArray = propertyList.filter((item) => item._id !== propertyId);
      setpropertyList(updatedArray)
      dispatch(setPropertyList(updatedArray))
      const closeModalButton = document.getElementById('closeModalButtondeldelete');
      if (closeModalButton) {
        closeModalButton.click();
      }
    }
  }

  const submitImages = async () => {
    // Create a FormData object
    const formData = new FormData();
  
    // Append each image to FormData with its unique name
    fieldValue.images.forEach(async (image, index) => {
      const uniqueName = fieldValue.imagesname[index]; 
      formData.append('name', uniqueName);
      formData.append('image', image); // Use a more meaningful name if needed
      console.log(formData)
      await postImages(formData);
    });

  };

 	
  const getpropertylist = async() => {
    if (propertyData.length === 0 || secondaryFetch){
    // if(permissions?.property?.admin === false){
    //   const res = await getPropertysecbyAgentId(currentUser._id)
    //     setpropertyList(res)
    //     dispatch(setPropertyList(res))
    // }
    // else if(currentUser.profileType==="superadmin" || permissions?.property?.admin){
      const res = await getPropertysec(currentUser?.company)
      setpropertyList(res)
      dispatch(setPropertyList(res))
  // }
}

    }

  useEffect(() => {
		
  
      
getpropertylist()
}, [permissions?.property])

useEffect(() => {
  const property = propertyList.find(item => item._id === propertyId)
  setFormDataProp(property)
  setExistingMediaImages(property?.mediaPic)
  // const images1 = propertyList.find(item => item._id === propertyId)?.mediaPic
  // const data:File[] = []
  // images1?.map((image, index) => {
  //   convertImageUrlToFile(PF+image, image).then((file) => {
  //     data[index] = file;
  //   });
  // });
  // setImages(data)
  // setFieldValue({
  //   images: data,
  //   imagesname: images1,
  // })
}, [propertyId])
  
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {
          propertyList?.map((item,index) => (
            <div className='col-xl-4' key={index}>
            <PropertyWidget className='card-xl-stretch mb-xl-8'  item={item}  handleid={handleid}/>
          </div>
          ))
        }
       
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}

      <div className="modal fade" tabIndex={-1} id="kt_modal_3">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Property</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
      <form >
              <div className="row">
                <div className="messagepicinput d-flex flex-center ">
                  <label htmlFor="messagegrouppic" className="messagesharefilelabel">
                  <div className="symbol symbol-100px border border-gray-300">
                  {imagePreview && (
                        <img
      
                          src={imagePreview ? imagePreview : grouppic}
                          alt='add'
                          className='object-fit-cover'
                        />
                      )}
                      {!imagePreview && <img className="object-fit-cover" src={formDataProp?.productPic !== "" ? PF+formDataProp?.productPic : grouppic} alt='add'/>}
                  </div>
  
                    <input type="file" id="messagegrouppic" onChange={handleImageChange} style={{ display: "none" }} />
                  </label>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formDataProp?.name}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-12">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formDataProp?.address}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Starting Price</label>
                  <input
                    type="text"
                    name="startingprice"
                    value={formDataProp?.startingprice}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                  </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Last Price</label>
                  <input
                    type="text"
                    name="lastprice"
                    value={formDataProp?.lastprice}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Latitude</label>
                  <input
                    type="text"
                    name="lat"
                    value={formDataProp?.lat}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Longitude</label>
                  <input
                    type="text"
                    name="lng"
                    value={formDataProp?.lng}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Project Type</label>
                  <input
                    type="text"
                    name="projectType"
                    value={formDataProp?.projectType}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Area In SqFt</label>
                  <input
                    type="text"
                    name="area"
                    value={formDataProp?.area}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Status</label>
                  <input
                    type="text"
                    name="status"
                    value={formDataProp?.status}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Hand Over</label>
                  <input
                    type="text"
                    name="handOver"
                    value={formDataProp?.handOver}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>


                <div>
                <input
                  type='file'
                  accept='image/*'
                  multiple
                  onChange={handleImageChangeMedia}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                </div>
                <div>
                {
                    existingMediaImages?.map((image, index) => (
                      <div
                        key={index}
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                      >
                        {/* begin::Preview existing avatar */}
                        <img
                          src={PF+image}
                          alt={`Preview ${index + 1}`}
                          className='image-input-wrapper w-125px h-125px'
                        />
                        <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='remove'
                          data-bs-toggle='tooltip'
                          title='Remove avatar'
                          onClick={() => handleRemoveExistingMediaImage(index)}
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                      </div>
                    ))
                  }
                {images.map((image, index) => (
                  <div
                  key={index}
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                  >
                    {/* begin::Preview existing avatar */}
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index + 1}`}
                      className='image-input-wrapper w-125px h-125px'
                    />

                    <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                    onClick={() => handleRemoveImage(index)}
                  >
                  <i className='bi bi-x fs-2'></i>
                  </span>
                  </div>
                ))}
                </div>
                  <button
                  type='button'
                  className='btn btn-sm btn-success my-2'
                  onClick={handleClick}
                >
                  <KTIcon iconName='plus' className='me-2'/>
                  Add Media Image
                </button>
              </div>
           
            </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtonEdit" 
        >
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}  >
          Edit
        </button>
      </div>
    </div>
  </div>
  </div>




  <div className="modal fade" tabIndex={-1} id="kt_modal_4">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete Property</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
          Delete This Property
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtondeldelete" 
        >
          Close
        </button>
        <button type="button" className="btn btn-danger" onClick={handledelete}  >
          Delete
        </button>
      </div>
    </div>
  </div>
  </div>
      
    </>
  )
}

export {SecondaryList}
