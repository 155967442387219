import {createSlice} from '@reduxjs/toolkit'
import {Property} from '../../pages/project/core/_models'

const initialState = {
  propertyList: [] as Property[],
  fetch: true,
}

const primarySlice = createSlice({
  name: 'primary',
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload
      state.fetch = false
    },
    setPrimaryPropertyRefetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setPropertyList, setPrimaryPropertyRefetch} = primarySlice.actions
export default primarySlice.reducer
