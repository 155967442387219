import {createSlice} from '@reduxjs/toolkit'
import {Property} from '../../pages/project/core/_models'

const initialState = {
  propertyList: [] as Property[],
  fetch: true,
}

const secondarySlice = createSlice({
  name: 'secondary',
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload
      state.fetch = false
    },
    setSecondaryPropertyRefetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setPropertyList, setSecondaryPropertyRefetch} = secondarySlice.actions
export default secondarySlice.reducer
